require('normalize.css/normalize.css');
require('../scss/index.scss');

// import Swiper JS
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

//fancybox
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

// isotope
require('./libs/isotope.pkgd.min.js');


// responsive
var screenWidth, screenHeight, mobile, tablet, mobileSmall;

function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
};

function calcScreen() {
  screenWidth = window.innerWidth;
  screenHeight = window.innerHeight;

  if (screenWidth <= 990) tablet = true; else tablet = false; 
  if (screenWidth <= 767) mobile = true; else mobile = false; 
  if (screenWidth <= 375) mobileSmall = true; else mobileSmall = false;   
}

// safari vh bug
// Get the viewport height and multiply it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then set the custom --vh value to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// ============================================================================
// window onload = show body + load functions

let onLoadFunctions = [];

$(window).on('load', function() {

    calcScreen();

    for (let i = 0; i < onLoadFunctions.length; i++) {
        onLoadFunctions[i]();
    }
    
    $('body').removeClass('hidden');
    $('body').addClass('visible');

    loadYoutubePreviews();
    loadVideoPlayers();
})


// ============================================================================
// utils // image preloader

function preloadImage(url){ // pass a url
  const img = new Image();
  img.src = url;
  return img
}

function preloadImages(images) { // pass an array of url
  for (var i = 0; i < images.length; i++) {
    images[i] = preloadImage(images[i])
  }
  return images
}


// ============================================================================
// utils // visibility

function isVisible(element) {

    if (element.length && element.css('display') !== 'none') {
        
        let parent_data = element.data('parent');

        if (parent_data) {

            const container = document.querySelector(parent_data).getBoundingClientRect();
            let top = element[0].getBoundingClientRect().top;
            let bottom = element[0].getBoundingClientRect().bottom;
            let height = element[0].getBoundingClientRect().height;

            return top <= container.top ? container.top - top <= height : bottom - container.bottom <= height;

        } else {
            var top_of_element = element.offset().top;
            var bottom_of_element = element.offset().top + $(window).outerHeight();
            var top_of_screen = $(window).scrollTop();
            var bottom_of_screen = $(window).scrollTop() + $(window).outerHeight();

            return (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element);
        }

        // console.log('display-none',element.attr('data-image'));
        // element.css('display','block');
        // element.css('visibility','hidden');
        // setTimeout(function(){
        //     top = element[0].getBoundingClientRect().top;
        //     bottom = element[0].getBoundingClientRect().bottom;

        //     element.removeAttr('style');
        // }, 10);

    } else {

        return false;
    }
}


// ============================================================================
// utils // lazy images and background images


function lazyLoadInit() {


    $('.lazy-image, .lazy-background, .lazy-fadein, .lazy-background-alt, .lazy-background-home').each( function() {

        let item = $(this);

        item.css('opacity', 0);
        setTimeout(()=>{ item.css('transition','opacity 0.5s ease'); }, 50);
    });

    lazyLoadUpdate();
}

function lazyLoadUpdate() {

    $('.lazy-image').each(function () {
        runIfVisible( $(this), loadLazyImg );
    });
    
    $('.lazy-background').each(function () {
        runIfVisible( $(this), loadLazyBg );
    });

    $('.lazy-background-alt').each(function () {
        runIfVisible( $(this), loadLazyBgAlt );
    });

    $('.lazy-background-home').each(function () {
        runIfVisible( $(this), loadLazyBgHome );
    });
    
    $('.lazy-fadein').each(function () {
        runIfVisible( $(this), loadLazyFadeIn );
    });

    function runIfVisible(element, callback){
        if (isVisible(element)) { 
            callback(element); 
        }
    }

    function loadLazyImg(img){

        img.attr('onload','this.style.opacity=1');
        img.attr('src',img.data('image'));
        img.removeClass('lazy-image');
    
    };
    
    function loadLazyBg(element){
    
        let new_data = element.data('image');
        let current_data = element.css('background-image');
    
        if (current_data == 'none') {
            current_data = new_data;
        } else {
            current_data += new_data;
        };

        element.css('background-image', current_data);
        element.css('opacity', 1);
        element.removeClass('lazy-background');

        // console.log('loaded lazy bg - ', new_data);

    };

    function loadLazyBgAlt(element){
    
        let imageLocation = element.data('src');
    
        var bgImg = new Image();
        bgImg.onload = function(){
            element[0].style.backgroundImage = 'url(' + bgImg.src + ')';
            element.css('opacity', 1);
            element.removeClass('lazy-background-alt');
        };
        bgImg.src = imageLocation;

        // console.log('loaded lazy bg alt - ', imageLocation);

    };

    function loadLazyBgHome(element){
    
        let imageLocation = element.data('src');
    
        var bgImg = new Image();
        bgImg.onload = function(){
            element[0].style.backgroundImage = 'url(' + bgImg.src + ')';
            element.removeClass('lazy-background-home');
        };
        bgImg.src = imageLocation;

    };

    function loadLazyFadeIn(element){

        element.find('[data-image]').each(function(){
            let img = $(this);
            img.attr('src',img.data('image'));
            img.removeClass('lazy-image');
        });
    
        setTimeout( function(){
            element.css('opacity', 1);
            element.removeClass('lazy-fadein');
        }, 200);

        // console.log('loaded lazy fadein - ', element.html().replace(/(\r\n|\n|\r)/gm," "));
    
    };

}
onLoadFunctions.push(lazyLoadInit);


// ============================================================================
// go to element

function gotoElement(elem, special = null){
    if (elem[0]) {   

        let header = $('header');

        let header_h = header.outerHeight();
        // let estimated = $(elem).offset().top - header_h - parseInt(header.css("padding-top"));
        let estimated = $(elem).offset().top - header_h;

        // particular scenario: PUBLICATIONS COMING FROM ANOTHER PAGE
        if (special === 'pub-newload') {

            estimated = $(elem).offset().top;

            // can skip directly to where the header is safely fixed)
            // console.log(`PUB scroll, INI SCROLL = ${parseInt($(window).scrollTop())}, EST = ${parseInt(estimated)}, HEADER = ${parseInt(header_h)}`);

            // skip
            $(window).scrollTop(300);
            
            $('#publications-list').css('opacity', 0);
            $('#publications-list').css('visibility', 'hidden');
            
            // iterate scroll until we have fixed values
            const limitedInterval = setInterval(() => {

                // let new_header_h = header.outerHeight();
                // let new_estimated = $(elem).offset().top - new_header_h - parseInt(header.css("padding-top"));
                let new_estimated = $(elem).offset().top;

                $(window).scrollTop(new_estimated);

                // console.log(`EST = ${parseInt(estimated)}/${parseInt(new_estimated)}, HEADER = ${parseInt(header_h)}/${parseInt(new_header_h)}`);

                if ( Math.abs(parseInt(new_estimated) - parseInt(estimated)) < 2 ) {

                    clearInterval(limitedInterval);
                    $('#publications-list').css('visibility', 'visible');
                    $('#publications-list').css('opacity', 1);

                } else {
                    estimated = new_estimated;
                }

            }, 300);

            // $(window).scrollTop(300);

            // setTimeout(() => {

            //     let target = $(window_hash);

            //     $('html, body').animate({
            //         scrollTop: $(target).offset().top,
            //         easing: 'linear',
            //     }, 500);

            //     $('#publications-list').css('visibility', 'visible');
            //     $('#publications-list').css('opacity', 1);

            // }, 1000);


        // EASY CASES, when header has fixed size and we're not on pubs (a lot of loadmore changing sizes of elements)
        } else if ( ($(window).scrollTop() > 150) && (special !== 'pub') ) { 

            // from 150 header doesnt change
            // simplest scenario, header height is fixed from this point
            console.log(`SIMPLE scroll, INI SCROLL = ${parseInt($(window).scrollTop())}, EST = ${parseInt(estimated)}, HEADER = ${parseInt(header_h)}`);

            $('html, body').animate({
                scrollTop: estimated,
                easing: 'linear',
            }, 500);


        // HARDEST SCENARIO
        } else {

            // header will change
            // loadmore elements will also change offsets
            console.log(`COMPLEX scroll, INI SCROLL = ${parseInt($(window).scrollTop())}, EST = ${parseInt(estimated)}, HEADER = ${parseInt(header_h)}`);

            let time;

            // IF WE ARE NOT ON PUBS, first we leave header change area
            if ( special !== 'pub') {
                // window.scroll({top: 150, left: 0, behavior: 'auto'});
                $(window).scrollTop(300);
                time = 200;
            } else {
                $('#publications-list').css('opacity', 0);
                $('#publications-list').css('visibility', 'hidden');
                window.scroll({top: estimated + 1000, left: 0, behavior: 'auto'});
                time = 400;
                console.log('pub special case');
            }

            const limitedInterval = setInterval(() => {

                let new_header_h = header.outerHeight();
                let new_estimated = $(elem).offset().top - new_header_h;
                // let new_estimated = $(elem).offset().top - new_header_h - parseInt(header.css("padding-top"));
                console.log(`EST = ${parseInt(estimated)}/${parseInt(new_estimated)}, HEADER = ${parseInt(header_h)}/${parseInt(new_header_h)}`);

                if (( Math.abs(parseInt(new_header_h) - parseInt(header_h)) < 2 
                        ) && ( Math.abs(parseInt(new_estimated) - parseInt(estimated)) < 2 )){

                    if ( special === 'pub') {
                        window.scroll({top: new_estimated, left: 0, behavior: 'smooth'});
                        $('#publications-list').css('visibility', 'visible');
                        $('#publications-list').css('opacity', 1);
                    }
                    clearInterval(limitedInterval);
                    console.log(`FINISHED`);

                } else {

                    if ( special !== 'pub') {
                        window.scroll({top: new_estimated , left: 0, behavior: 'smooth'});
                    } else {
                        window.scroll({top: new_estimated + 1000, left: 0, behavior: 'smooth'});
                    }

                    console.log(`DONE`);

                    header_h = new_header_h;
                    estimated = new_estimated;
                
                }
            }, time);
        }
    }
}

// ============================================================================
// HOMEPAGE
// ============================================================================

// homepage text adjustment

if ( $(' .template-homepage ').length ) {
    onLoadFunctions.push(loadTextAdjustment);
    onLoadFunctions.push(setHomepageInteraction);
}

function loadTextAdjustment(){

    let text_container = $('.text-container');
    let minSize, maxSize;

    if ( mobile ) {
        maxSize = 31;
        minSize = 20;  
    } else if ( tablet ) {
        maxSize = 45;
        minSize = 35;
    } else {
        maxSize = 60;
        minSize = 30;
    }

    console.log(mobile,tablet,screenWidth, maxSize,minSize);

    text_container.children().each(function(index){

        let text = $(this);
        let current = parseInt(text.css('font-size'));

        if (current < minSize) {
            text.css('font-size', minSize + 'px');
            delay(loadTextAdjustment,10);

        } else if (current > maxSize) {
            text.css('font-size', maxSize + 'px');
            delay(loadTextAdjustment,10);

        } else { 
            if (text.height() > text_container.height() && current > 32 ) {

                let new_size = current*0.95;
                if (new_size >= minSize) {
                    text.css('font-size', new_size + 'px');
                    delay(loadTextAdjustment,10);

                } else {
                    endTextAdjustment();
                }
            }
            else if (current < 60 && text.height() < text_container.height() - 60 ) {

                let new_size = current*1.05;
                if (new_size <= maxSize) {
                    text.css('font-size', new_size + 'px');
                    delay(loadTextAdjustment,10);

                } else {
                    endTextAdjustment();
                }
            } else {
                endTextAdjustment();
            }
        }

        function delay(callback,time){
            setTimeout(function(){
                callback();
            },time);
        }

        function endTextAdjustment(){
            if (text.hasClass('hp-visible')) {
                text.css('opacity', 1);
            } else {
                text.css('opacity', 0);
            }
        }
    });
}

// homepage interaction

function setHomepageInteraction(){

    let container = $( '.homepage-selectors' );
    let backdrop = $( '#backdrop');
    let main = $( 'main' );

    let art = $( '#home-selector-art' );
    let sci = $( '#home-selector-science' );

    let text_ini = $( '#home-text-initial' );
    let text_art = $( '#home-text-art' );
    let text_sci = $( '#home-text-science' );

    let image_art = $( '#image-art');
    let image_sci = $( '#image-science');

    if ( mobile || tablet || isTouchDevice() ) {

        main.addClass( 'active' );

        // remove anchors to personalize interaction
        let artURL = art.find('a').attr('href');
        let scienceURL = sci.find('a').attr('href');
        art.find('a').removeAttr('href');
        sci.find('a').removeAttr('href');

        sci.on('click', function() {

            art.find('a').removeAttr('href');

            text_ini.removeClass('hp-visible');
            text_ini.css('opacity',0);
            
            text_art.css('opacity',0);
            text_art.removeClass('hp-visible');

            text_sci.addClass('hp-visible');
            text_sci.css('display','block');            
            loadTextAdjustment();

            setTimeout(function(){
                sci.find('a').attr('href',scienceURL);
            },100);

            container.addClass('active');
            sci.addClass('active');
            art.removeClass('active');

        });
    
        art.on('click', function() {

            sci.find('a').removeAttr('href');

            text_ini.removeClass('hp-visible');
            text_ini.css('opacity',0);
            
            text_sci.css('opacity',0);
            text_sci.removeClass('hp-visible');

            text_art.addClass('hp-visible');
            text_art.css('display','block');
            loadTextAdjustment();

            setTimeout(function(){
                art.find('a').attr('href',artURL);
            },100);

            container.addClass('active');
            art.addClass('active');
            sci.removeClass('active');

        });


        // empty listeners
        container.on('mouseenter', function() {}).on('mouseleave', function() {});
        sci.on('mouseenter', function() {}).on('mouseleave', function() {});
        art.on('mouseenter', function() {}).on('mouseleave', function() {});

        // set opacity to one
        image_art.css('opacity',1);
        image_sci.css('opacity',1);

    } else {

        main.removeClass( 'active' );

        text_art.css('opacity',0);
        text_sci.css('opacity',0);
        image_art.css('opacity',0);
        image_sci.css('opacity',0);

        backdrop.css('transition','opacity 0.4s ease');
        text_ini.css('transition','opacity 0.4s ease');
        text_art.css('transition','opacity 0.4s ease');
        text_sci.css('transition','opacity 0.4s ease');
        image_art.css('transition','opacity 0.4s ease');
        image_sci.css('transition','opacity 0.4s ease');
    
        setTimeout(function() { //avoid initial display
            text_art.css('display','block');
            text_sci.css('display','block');
            image_art.css('display','block');
            image_sci.css('display','block');
        },500);

        container.on('mouseenter', function() {
            enterDisciplines();
        }).on('mouseleave', function() {
            leaveDisciplines();
        });
    
        sci.on('mouseenter', function() {
            displayScience();
        }).on('mouseleave', function() {
            hideScience();
        });
    
        art.on('mouseenter', function() {
            displayArt();
        }).on('mouseleave', function() {
            hideArt();
        });
    
        //-- Image preloading --//
        function hpImgPreload() {
            let img1 = image_art.css('background-image').replace(/^url\(['"](.+)['"]\)/, '$1');
            let img2 = image_sci.css('background-image').replace(/^url\(['"](.+)['"]\)/, '$1');
            preloadImages([img1, img2]);
        }

        if (image_art[0]) {
            onLoadFunctions.push(hpImgPreload);
        }

    }


    function enterDisciplines() {

        text_ini.removeClass('hp-visible');
        text_ini.css('opacity',0);

        main.addClass( 'active' );
    }

    function leaveDisciplines() {

        // text_ini.css('opacity',1);
        text_ini.addClass('hp-visible');
        loadTextAdjustment();

        main.removeClass( 'active' );
    }

    function displayArt() {
        text_art.addClass('hp-visible');
        loadTextAdjustment();
        // text_art.css('opacity',1);
        image_art.css('opacity',1);
    }

    function hideArt() {
        text_art.css('opacity',0);
        text_art.removeClass('hp-visible');
        image_art.css('opacity',0);
    }

    function displayScience() {
        text_sci.addClass('hp-visible');
        loadTextAdjustment();
        // text_sci.css('opacity',1);
        image_sci.css('opacity',1);
    }

    function hideScience() {
        text_sci.css('opacity',0);
        text_sci.removeClass('hp-visible');
        image_sci.css('opacity',0);
    }
}

// ============================================================================
// HEADER ANIMATION
// ============================================================================

let update_header_settings = {};


function loadHeaderAnimation(){
    
    update_header_settings = {

        menuPadding: 0.7,
        menuPaddingMin: 0.3,
    
        logosMargin: 1.4,
        logosMarginMin: 0.7,
    
        sideFont: 1,
        sideFontMin: 0.8,
    
        menuFont: 2,
        menuFontMin: 1.4,
    
        logosGap: 2,
        logosGapMin: 1,
    
        mainLogoMobileMin: 82,
        mainLogoMobile: 100,
    
        selectorsHeightMobileMin: 44,
        selectorsHeightMobile: 71,
    
        selectorsFontMobileMin: 22,
        selectorsFontMobile: 30,
    }
    
    if ($('.art-universe').length) {
        if ( screenWidth < 1100 && screenWidth >= 900 ){
            update_header_settings.sideFontCSS = 'clamp(21px, 2vw, 33px)*0.8';
    
        } else if (screenWidth < 900) {
            update_header_settings.sideFontCSS = 'clamp(21px, 2vw, 33px)*0.7';
        } else {
            update_header_settings.sideFontCSS = 'clamp(21px, 2vw, 33px)*0.87';
        }
    } else {
        update_header_settings.sideFontCSS = 'clamp(21px, 2vw, 33px)';
    }

    updateHeader(update_header_settings);
    adjustMainPadding();

    setTimeout(function(){
        hideHeaderByFooter();
    },500);

};
onLoadFunctions.push(loadHeaderAnimation);


function updateHeader(settings){

    let header = $('.header-new');
    let scrollTop = $(document).scrollTop();
    calcScreen();

    function updateVal(ini,min){
        return ini - (ini-min)/100*scrollTop;
    }

    let menuPaddingNew = updateVal(settings.menuPadding,settings.menuPaddingMin);
    let logosMarginNew = updateVal(settings.logosMargin,settings.logosMarginMin);

    if ($('.art-universe').length) {
        if ( screenWidth < 1100 && screenWidth >= 900 ){
            settings.sideFontCSS = 'clamp(21px, 2vw, 33px)*0.8';
        } else if (screenWidth < 900) {
            settings.sideFontCSS = 'clamp(21px, 2vw, 33px)*0.7';
        } else {
            settings.sideFontCSS = 'clamp(21px, 2vw, 33px)*0.87';
        }
    }

    let sideFontNew = updateVal(settings.sideFont,settings.sideFontMin);
    let menuFontNew = updateVal(settings.menuFont,settings.menuFontMin);
    let logosGapNew = updateVal(settings.logosGap,settings.logosGapMin);
    let mainLogoMobileNew = updateVal(settings.mainLogoMobile,settings.mainLogoMobileMin);
    let selectorsHeightMobileNew = updateVal(settings.selectorsHeightMobile,settings.selectorsHeightMobileMin);
    let selectorsFontMobileNew = updateVal(settings.selectorsFontMobile,settings.selectorsFontMobileMin);

    if ($('.hero-cover').length) {

        // if( scrollTop >= $('.hero-cover').height()*0.1 ) {
        if( scrollTop > 0 ) {

            $('header').removeClass('no-bg');

            if ($('.art-universe').length) {
                $('header').addClass('black-color');
                $('header').removeClass('white-color');
            } else {
                $('header').addClass('white-color');
                $('header').removeClass('black-color');
            }

        } else {

            $('header').addClass('no-bg');

            if ($('.art-universe').length) {
                $('header').addClass('white-color');
                $('header').removeClass('black-color');

            } else {
                $('header').addClass('white-color');
                $('header').removeClass('black-color');

            }
        }
    }


    if( scrollTop >= 100 ) {

        calcScreen();
        if (!mobile) {
            header.find('.side').addClass('reduced');
            header.find('.button-side').removeAttr('style');

            header.find('nav.menu').css('padding-top',settings.menuPaddingMin+'rem');
            header.find('nav.menu').css('padding-bottom',settings.menuPaddingMin+'rem');
            header.find('nav.menu li').css('font-size',`${settings.menuFontMin}rem`);

            header.find('.logos').css('height', `calc((53vw)*(225/1620) - ${scrollTop/4}px)`);
            header.find('.logos').css('gap',settings.logosGapMin+'vw');
            header.find('.logos').css('margin-bottom',settings.logosMarginMin+'rem')
        } else {

            header.find('nav.menu li').removeAttr('style');

            header.find('.logos .main img').css('width', settings.mainLogoMobileMin + "%");
            header.find('.homepage-selectors li').css('height', settings.selectorsHeightMobileMin + "px");
            header.find('.homepage-selectors li h1').css('font-size', settings.selectorsFontMobileMin + "px");

            header.find('.logos').removeAttr('style');

        }


    } else {
        
        // if( scrollTop >= 50 ) {
        // }
        if (!mobile) {

            header.find('.button-side').css('font-size',`calc(${sideFontNew}*${settings.sideFontCSS})`);
            
            if ( $('.art-universe').length && scrollTop >= 30 && screenWidth <= 1000) {
                header.find('.side').addClass('reduced');
                header.find('.button-side').removeAttr('style');
            } else {
                header.find('.side').removeClass('reduced');
            }

            header.find('nav.menu').css('padding-top',menuPaddingNew+'rem');
            header.find('nav.menu').css('padding-bottom',menuPaddingNew+'rem');
            header.find('nav.menu li').css('font-size',`${menuFontNew}rem`);

            header.find('.logos').css('height', `calc((53vw)*(225/1620) - ${scrollTop/4}px)`);
            header.find('.logos').css('gap',logosGapNew+'vw');
            header.find('.logos').css('margin-bottom',logosMarginNew+'rem');
        } else {

            header.find('nav.menu li').removeAttr('style');
            header.find('nav.menu').removeAttr('style');

            header.find('.logos .main img').css('width', mainLogoMobileNew + "%");
            header.find('.homepage-selectors li').css('height', selectorsHeightMobileNew + "px");
            header.find('.homepage-selectors li h1').css('font-size', selectorsFontMobileNew + "px");

            header.find('.logos').removeAttr('style');

            // header.css('min-height', headerHeightNew + "px");

        }
    }
}

function hideHeaderByFooter(){
    if (isVisible($('footer').find('#social-menu')) && !$('header nav.menu').hasClass('show')) {
        $('header').addClass('hidden');
    } else {
        $('header').removeClass('hidden');
    }
}


// ADJUST MAIN PADDING

function adjustMainPadding(){
    let main = $('main.xtra-padding');
    let anchors = $('div.anchor');
    let header = $('header');

    let pad = header.css('padding-top');

    if (mobile) {
        main.css('padding-top',`calc(${header.height()}px + ${pad})`);
        anchors.css('top',`calc(1px - ${header.height()}px - ${pad})`);
    } else {
        main.removeAttr("style");
    }
    
}

// ============================================================================
// home of sections zoom on scroll
// if ( $(' .template-home-common ').length ) {

//     let screenHeight;

//     screenHeight = $(window).height(); 

//     $(window).resize(function(){
//         screenHeight = $(window).height(); 
//     });

//     $(window).scroll(function(){

//         let y = $(this).scrollTop();
//         let initialVal = 100;
//         let increment = 15;
//         let zoom = initialVal + increment * (y/screenHeight);
//         $('.hero-cover').css('background-size',zoom + '%');
//         console.log(y,zoom);
//     });
// };


// ============================================================================
// HASH, SMOOTH SCROLLING TO ELEMENTS, ETC

let window_hash = window.location.hash;

if (!$('.template-publications')[0]) {

    // hide pubs
    window.location.hash = '';
    history.pushState(null, null, location.pathname + location.search);
    
}



function readWindowHash() {

    // console.log('hash', window_hash);

    // SPECIAL PROCEDURE FOR PUBLICATIONS
    if ($('.template-publications')[0]) {

        // hide pubs
        $('#publications-list').css('opacity', '0');

        setTimeout(function() {
            gotoElement($(window_hash), 'pub-newload');
            // lazyLoadUpdate();
        },100);


    // SPECIAL PROCEDURE FOR ABOUT BOOKS HASH
    } else if (window_hash == '#about_books') {

        var books = $(window_hash);
        var menu_books = $('#about_books_menu');

        // books.removeAttr('id');
        menu_books.removeAttr('href');

        toggleAnimatedCrossDisplay(books);

        setTimeout(function() {
            console.log('goto books due to hash');
            gotoElement(books);
        },10);

    // GENERIC SMOOTH SCROLL
    } else {
        setTimeout(function() {
            console.log('generic scroll');
            gotoElement($(window_hash));
            // lazyLoadUpdate();
        },100);
    }
}

if (window_hash) {
    // console.log('window_hash', window_hash);
    onLoadFunctions.push(readWindowHash);
    // readWindowHash();

}



// ============================================================================
// publications

if ( $(' .template-publications ').length ) {

    // automatically go to target publication, if provided
    // function gotoPub(){

    //     let id_selector = '#' + $('main').data('target-id');
    //     let target = $(id_selector);

    //     target.find('.abstract').css('display','block');
    //     target.find('.abstract-btn div').html('CLOSE');    

    //     let header_h = $('header').outerHeight();
    //     // let estimated = $(elem).offset().top - header_h - parseInt(header.css("padding-top"));
    //     $('html, body').animate({
    //         scrollTop: $(target).offset().top - header_h,
    //         easing: 'linear',
    //     }, 500);
    // }

    // if ( $('main').data('target-id') ) {
    //     onLoadFunctions.push(gotoPub);
    // }


    // abstract button interaction

    $('.abstract-btn').click(function(){

        let id_selector = '#' + $(this).data('id');

        $(id_selector).find('.abstract').slideToggle("swing");

        if ($(id_selector).find('.abstract-btn div').html() == 'ABSTRACT') {
            $(id_selector).find('.abstract-btn div').html('CLOSE');
            $(id_selector).find('.abstract-btn').addClass('active');
        } else {
            $(id_selector).find('.abstract-btn div').html('ABSTRACT');
            $(id_selector).find('.abstract-btn').removeClass('active');
        }

    });

    if (window.location.hash.substr(1)) {
        let id_selector = '#' + window.location.hash.substr(1);

        $(id_selector).find('.abstract').css('display','block');
        $(id_selector).find('.abstract-btn div').html('CLOSE');
    }

}


// ============================================================================
// works

if ( $(' .template-works ').length ) {
    // let worktype = $(' .template-works ').data('active-work-type');
    // $('#type-'+worktype).addClass('active');

    let btn = $('#display-selector');
    let list_view = $('#list-view');
    let gallery_view = $('.default-grid');

    btn.on('click', () => {

        if (list_view.hasClass('collapse')) {
            list_view.removeClass('collapse');
            gallery_view.addClass('collapse');
            btn.html('GALLERY VIEW');
        } else {
            list_view.addClass('collapse');
            gallery_view.removeClass('collapse');
            btn.html('LIST VIEW');
        }
    });
}

if ( $(' .template-NFTs ')[0] ) {

    // automatically go to target publication, if provided
    function gotoNFT(){

        let id_selector = '#NFT_' + $('main').data('target-id');
        let target = $(id_selector);

        $('html, body').scrollTop(0);    
        gotoElement(target);
    }

    if ( $('main').data('target-id') ) {
        onLoadFunctions.push(gotoNFT);
    }
}


// // category selector
// if ( $(' .template-works ').length && $('.category-selector').length ) {

//     let types = [ 'VR' , '2D', '3D' , 'NFT'];
//     let containers = $('.works-grid');
//     let buttons = $('.category-selector .button');

//     // make an array of the original flow types of grid containers
//     let flow_types = [];
//     containers.each(function () {
//         let flow_type;
//         if (!(flow_type = $(this).data('type'))) {
//             flow_type = 'block';
//         }
//         flow_types.push({
//             id: $(this).attr('id'),
//             type: flow_type
//         });
//     });

//     if (window.location.hash.substr(1)) {
//         loadType(types[3]); // only NFTs can be accessed this way
//     } else { 
//         loadType(types[0]); // first type is default site loading
//     }

//     // set click event listeners
//     buttons.click(function () {
//         let button_id = $(this).attr('id');
//         loadType(button_id.substring(5));
//     });

//     function loadType(type) {

//         // reset
//         containers.css('display','none'); 
//         buttons.removeClass('active');

//         // loadMore reset
//         let loadmore_container_class = '.delayed-loadmore-container';
//         let loadmore_items_class = '.loadmore-item';
//         $(loadmore_container_class).find(loadmore_items_class).css('display', 'none'); // test other way speed

//         // button acivation
//         $('#type-'+type).addClass('active');

//         // display grids of active type
//         let id = 'first-'+type;
//         if ($('#'+id).length) {
//             $('#'+id).css('display',findTypeById(id));
//         }
//         id = 'next-'+type;
//         if ($('#'+id).length) {
//             $('#'+id).css('display',findTypeById(id));
//         }

//         // activate loadMore containers
//         loadMore($(window), loadmore_container_class);
//     }

//     function findTypeById(id){
//         let type_object = flow_types.filter( (entry) => entry.id === id );
//         return type_object[0].type
//     }

// }


// ============================================================================
// SWIPERS

if ( $(' .template-detail-common ').length ) {

    function loadCarouselSwiper(){
        const swiper = new Swiper('.swiper', {
            // Optional parameters
            loop: true,
            // createElements: true,
            // Navigation arrows
            navigation: {
                nextEl: '.button-next',
                prevEl: '.button-prev',
            },
            observer: true, 
            observeParents: true
        });

        swiper.on('slideChange', function () {
            // stopVideoPlayers();
        });
    }

    onLoadFunctions.push(loadCarouselSwiper);

};

// ============================================================================
// VIDEOS --> 
// ============================================================================

// VIDEO PLAYER
// - lazy load
// - updates when video ends
// - bug fixes for mobile
// - controls: play
// - WIP: mute, rewind controls

function loadVideoPlayers() {
    const video_players = document.querySelectorAll('.js-video-player');

    const isTouchDevice =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;

    function isVideoPlaying(video) {
        return !!(
            video.currentTime > 0 &&
            !video.paused &&
            !video.ended &&
            video.readyState > 2
        );
    }

    Array.from(video_players).forEach((video_player) => {
        const video = video_player.querySelector('video');
        const play = video_player.querySelector('.js-video-player-play') || video_player;

        if (video) {
            // lazy load
            const source = video_player.querySelector('source');
            const video_url = source.getAttribute('data-src');

            // fix for mobile
            if (isTouchDevice) {
                source.src = video_url + '#t=0.001';
            } else {
                source.src = video_url;
            }
            video.load();
            video.play();
            play.classList.add('playing');

            // controls
            play.addEventListener('click', () => {
                if (isVideoPlaying(video)) {
                    video.pause();
                    play.classList.remove('playing');
                } else {
                    video.play();
                    play.classList.add('playing');
                }
            });

            // video end event listener
            // video.addEventListener(
            //     'ended',
            //     () => {
            //         play.classList.remove('playing');
            //     },
            //     false
            // );
        }
    });
}

function stopVideoPlayers() {
    const video_players = document.querySelectorAll('.js-video-player');

    Array.from(video_players).forEach((video_player) => {
        const video = video_player.querySelector('video');
        const play = video_player.querySelector('.js-video-player-play') || video_player;

        if (video) {
            video.pause();
            play.classList.remove('playing');
        }
    });
}

// if ( $(' .template-art-image ').length ) {

//     function loadIFrameSwiper(){

//         // $('.slick-art-image').slick({
//         //     slidesToShow: 1,
//         //     slidesToScroll: 1,
//         //     arrows: false,
//         //     fade: true,
//         //     asNavFor: '.slider-nav'
//         //   });

//         //   $('.slick-art-image-thumbs').slick({
//         //     slidesToShow: 3,
//         //     slidesToScroll: 1,
//         //     asNavFor: '.slider-for',
//         //     dots: true,
//         //     centerMode: true,
//         //     focusOnSelect: true
//         //   });

//         const swiper_thumbs = new Swiper('.swiper-art-image-thumbs', {
//             // loop: true,
//             spaceBetween: 10,
//             slidesPerView: 4,
//             freeMode: true,
//             watchSlidesProgress: true,
//             navigation: false,
//         });

//         const swiper = new Swiper('.swiper-art-image', {
//             // Optional parameters
//             // loop: true,
//             // slidesPerView: 2,
//             // centeredSlides: false,
//             spaceBetween: 10,          
            
//             // Navigation arrows
//             navigation: {
//               nextEl: '.button-next',
//               prevEl: '.button-prev',
//             },
//             // observer: true, observeParents: true,

//             thumbs: {
//                 swiper: swiper_thumbs,
//             },

//             // pagination: {
//             //   el: ".swiper-pagination",
//             //   clickable: true,
//             // },

//             // effect: "coverflow",
//             // grabCursor: true,
//             // coverflowEffect: {
//             //     rotate: 50,
//             //     stretch: 0,
//             //     depth: 100,
//             //     modifier: 1,
//             //     slideShadows: true,
//             // },

//         });

//     }
//     onLoadFunctions.push(loadIFrameSwiper);

// };

// ============================================================================
// Fancybox

Fancybox.bind("[data-fancybox]", {
    // Your options go here
    Toolbar: false,
    dragToClose: false,
    closeButton: "top",

    Image: {
        fit: "contain",
    },
});


// ============================================================================
// ANIMATIONS --> 
// ============================================================================

// ANIMATED DIV FOLLOWING CURSOR (message)
// ============================================================================

function setMessage(msg,div) {
    div.html(msg);
}

function updateDisplay(e,div) {
    div.css('left', e.pageX + 'px');
    div.css('top', e.pageY + 'px');
}

function showBox(div) {
    if (!mobile) {
        div.css('transition', 'opacity 0.6s');
        div.css('opacity', '1');
    }
}

function hideBox(div) {
    div.css('opacity', '0');
}

function mouseAnimate() {

    var boxes = $('.mouseanimate');

    if (boxes.length) {

        let animatedDiv = $("<div>", {id: "animatedDiv", "class": "mouseanimate-div button"});
        $(' body ').append(animatedDiv);

        boxes.each( function(){

            let box = $(this);
            let message = box.attr('data-mouseanimate');
            setMessage(message,animatedDiv);

            box.mouseenter((e) => {
                updateDisplay(e,animatedDiv);
                showBox(animatedDiv);
            });
            box.mousemove((e) => updateDisplay(e,animatedDiv));
            box.mouseleave((e) => hideBox(animatedDiv));
        });

    }
}
onLoadFunctions.push(mouseAnimate);


// ANIMATED THUMBNAIL FOLLOWING CURSOR (message)
// ============================================================================

function setSrc(url,element) {
    element.attr('src',url);
}

function updateThumbPosition(event,element) {
    element.css('left', event.pageX + 'px');
    element.css('top', event.pageY + 'px');
}

function showThumb(element) {
    element.css('opacity', '1');
    element.css('transition','top 0.3s ease, left 0.3s ease')
}

function hideThumb(element) {
    element.css('opacity', '0');
    // element.removeAttr("style");
}

function mouseAnimateThumbs() {

    let boxes = $('.animated-thumb-wrapper');

    boxes.each( function(){

        let box = $(this);
        let thumb = box.find('img');
        let thumb_url = thumb.data('thumb');

        box.mouseenter((e) => {
            setSrc(thumb_url,thumb);
            updateThumbPosition(event,thumb);
            showThumb(thumb);
        });
        box.mousemove((e) => updateThumbPosition(e,thumb));
        box.mouseleave((e) => hideThumb(thumb));
    });
}
onLoadFunctions.push(mouseAnimateThumbs);


// POPUPS (iframe)
// ============================================================================

if ( $('#iframe-popup').length ) {

    let triggers = $(' .popup-trig ');
    let iframe_container = $('#iframe-popup');
    let iframe = iframe_container.find('iframe');
    let bg = $('#iframe-bg');
    // let bg_elements = $('footer, header, main');

    function showIframe(){
        bg.fadeIn(500);
        iframe.fadeIn(1000);
        setTimeout(() => iframe_container.fadeIn(500), 500);
        // bg_elements.addClass('popup-container-effect');
    }

    function hideIframe(){
        iframe_container.fadeOut(100);
        iframe.fadeOut(100);
        bg.fadeOut(100);
        // bg_elements.removeClass('popup-container-effect');
    }

    triggers.click(function () {

        let url = $(this).data('popup');
        let type = $(this).data('popup-type');

        iframe.attr('src',url);

        if (type == 'video') {
            let yt_url = url;
            let yt_id_i = yt_url.indexOf('v=') + 2;
            let yt_id = yt_url.slice(yt_id_i, yt_id_i + 11);

            let url = `https://www.youtube.com/embed/${yt_id}?autoplay=1`;  
        }

        iframe.attr('src',url);
        iframe_container.removeClass();
        iframe_container.addClass(type);

        showIframe();

        bg.click(function(){ 
            hideIframe(); 
        });
        
        iframe_container.find('.close').click(function(){ 
            hideIframe(); 
        });

    });

};


// ANIMATED CROSS SLIDES
// ============================================================================

function toggleAnimatedCrossDisplay(element){

    let cross = element.find('.animated-cross');

    let display = element.next();
    let time = 0;

    // load images to src if not loaded
    let images_to_load = display.find('[data-ac-image]');
    if (images_to_load[0]) {
        time = 300; // give time
        images_to_load.each(function(){
            let image = $(this);
            let src = image.data('ac-image');
            image.attr('src',src);
            image.removeAttr('data-ac-image');
        });
    }

    // toggle active cross
    cross.toggleClass('active');

    // toggle content display
    setTimeout( function() {
        $('#'+ cross.data('cross') ).slideToggle();

        // load masonry
        var masonry = display.find('.masonry-grid:not(.loaded)');
        if (masonry.length) {
            var iso = new Isotope( masonry[0] , {
                itemSelector: '.masonry-item',
                masonry: {
                    horizontalOrder: true,
                    gutter: 15
                },
                columnWidth: '.masonry-item',
                // stagger: 30
            });    
            masonry.addClass('loaded');
            console.log('loaded masonry');    
        }
    }, time);
    
    // toggle spacing adjustments
    // if (cross.hasClass('noline-trigger')) { element.parent().toggleClass('noline'); }
    // if (cross.hasClass('space-trigger')) { element.parent().toggleClass('subheading-L'); }


}


// behaviour with books inside about
if ( $(' .template-about-science ').length ) {

    $('#about_books_menu').on('click',function(){

        // si está el menu mobile desplegado, esconderlo
        $("header nav.menu").removeClass("show");

        let books_section = $('#about_books');
        gotoElement(books_section);

        if (!books_section.find('.animated-cross').hasClass('active')) {
            setTimeout(function(){
                toggleAnimatedCrossDisplay(books_section);
            },400);
        }
    });
}



if ( $(' .template-about-common ').length ) {

    $('html').css('scroll-behavior', 'smooth');

    // animated crosses books, videos

    $('.animated-cross').parent().css('cursor', 'pointer');

    $('.animated-cross').parent().click(function(){ toggleAnimatedCrossDisplay($(this)) });


// READ MORE
// ============================================================================

    $('.job-text-btn').click(function(){

        let id_selector = '#' + $(this).data('id');

        $(id_selector).find('.job-text').slideToggle("swing");

        if ($(id_selector).find('.job-text-btn').html() == 'READ MORE') {
            $(id_selector).find('.job-text-btn').html('READ LESS');
            $(id_selector).find('.job-text-btn').addClass('active');
        } else {
            $(id_selector).find('.job-text-btn').html('READ MORE');
            $(id_selector).find('.job-text-btn').removeClass('active');
        }

    });

    // if (window.location.hash.substr(1)) {
    //     let id_selector = '#' + window.location.hash.substr(1);

    //     $(id_selector).find('.job-text').css('display','block');
    //     $(id_selector).find('.job-text-btn').html('READ LESS');
    // }


// ISOTOPE MASONRY
// ============================================================================

    let loadIsotope = function () {

        var list = document.querySelectorAll('.grid-press.masonry-grid');

        for (let element of list) {
            var iso = new Isotope( element , {
                itemSelector: '.masonry-item',
                masonry: {
                    horizontalOrder: true,
                    gutter: 15
                },
                // stagger: 30
            });          
        }
    }

    onLoadFunctions.push(loadIsotope);


    // about mobile menu


    $('#menu-about-trigger').click(function(){

        $('nav.menu-about .ul').slideToggle("swing");

    });

    $('nav.menu-about .ul .menu-about-link').click(function(){

        let selected_submenu_item = $(this);

        if (mobile) {
            $('#menu-about-selected-section').text(selected_submenu_item.text());
            $('nav.menu-about .ul').slideToggle("swing");
        }

        let target = selected_submenu_item.data('about-section');
        if (target) {
            gotoElement($(target));
        }

    });

    loadIsotope();
};

// ============================================================================
// anchor problems in about due to lazy images size variation

// if ( $(' .lazy-anchor ').length ) {
//     $('body').on('click', 'a.lazy-anchor', function(e) {

//         // Get the hash. In this example, "#myDestinationAnchor".
//         var targetSelector = this.hash;
//         var $target = $(targetSelector);
    
//         // Animate the scroll to the destination...
//         $('html, body').animate(
//             {
//                 scrollTop: $target.offset().top // Scroll to this location.
//             }, {
//                 // Set the duration long enough to allow time
//                 // to lazy load the elements.
//                 duration: 2000,
    
//                 // At each animation step, check whether the target has moved.
//                 step: function( now, fx ) {
    
//                     // Where is the target now located on the page?
//                     // i.e. its location will change as images etc. are lazy loaded
//                     var newOffset = $target.offset().top;
    
//                     // If where we were originally planning to scroll to is not
//                     // the same as the new offset (newOffset) then change where
//                     // the animation is scrolling to (fx.end).
//                     if(fx.end !== newOffset)
//                         fx.end = newOffset;
//                 }
//             }
//         );
//     });
// };

// ============================================================================
// SEARCH
// ============================================================================

// Page preparation

function prepareSearchPageListeners() {

    $('.menu .search-item, .flex-container .search').on('click', showSearch);

    $('.page-search').scroll(function(){
        lazyLoadUpdate();
        loadMoreUpdate();
    });

    $('input[name=text]').on('focusout', () => {

        if (isTouchDevice()) {
            $('form').submit();
        }
    });       
    
}
onLoadFunctions.push(prepareSearchPageListeners);




// SHOW search page


function showSearch() {

    console.log('showSearch');

    // reset search form
    $('form').show();
    $('input[name=text]').val('');        

    // reset search page
    $('#search-tags').show();
    $('#search-results').empty();
    $('#search-results').css('opacity', 0);
    $('.footer-results').css('display', 'none');
    
    // show search page
    $('.page-search').fadeIn();
    $('input[name=text]').focus();

    $('.page-search').animate({
        scrollTop: 0,
        easing: 'swing',
    }, 400);
}

// HIDE search page

$('.page-search .close, #search-art-logos, #search-science-logos').on('click', hideSearch);

function hideSearch(speed = 'fast') {

    console.log('hideSearch');

    // hide search page
    $('.page-search').fadeOut(speed);

    // replace scrollbar
    $('body').css('overflow', 'auto');
    // $('main, header, footer').css('padding-right', '1.4rem');

    // remove min size of page search container
    $('.page-search .container').removeAttr('style');

    // footer reset
    $('.page-search .footer-results').css('display','none');
    $('.page-search .footer-results').css('opacity',0);

    // hide animated box bug
    hideBox($('#animatedDiv'));

}

// SEARCH SUBMISISON - BY FORM

$('form').on('submit', function(event){

    let url = $(this).data('url');
    let search_val = $('input[name=text]').val();

    showSearchLoader(search_val);
    event.preventDefault();

    $.ajax({
        type: 'GET',
        url: url,
        data: {
            'text': search_val,
            'csrfmiddlewaretoken': $('input[name=csrfmiddlewaretoken]').val(),
        },
        complete: function (data) {
            prepareResultsPage(data.responseText);
        },
        dataType: 'html'
    });
});

// SEARCH SUBMISISON - BY tag links in the bottom of search page

$('.tag-loader').on('click', function(event){

    let url = $(this).data('url');
    let slug = $(this).data('slug');
    let name = $(this).data('name');

    showSearchLoader(name);
    event.preventDefault();

    console.log('CLICK on tag loader');

    $.ajax({
        type: 'GET',
        url: url,
        data: {
            'slug': slug,
            'name': name
        },
        complete: function (data) {
            prepareResultsPage(data.responseText);
        },
        dataType: 'html'
    });
});

// SEARCH SUBMISISON - BY tag links in detail pages

$('.results-loader').on('click', function (e) {

    let url = $(this).data('url');
    let slug = $(this).data('slug');
    let name = $(this).data('name');

    // $('form').hide();
    // $('#search-tags').hide();
    // $('#search-results').empty();
    // $('#search-results').css('opacity', 1);
    showSearch();
    showSearchLoader(name);
    // $('.page-search').fadeIn("slow");
    event.preventDefault();

    $.ajax({
        type: 'GET',
        url: url,
        data: {
            'slug': slug,
            'name': name
        },
        complete: function (data) {
            prepareResultsPage(data.responseText);
        },
        dataType: 'html'
    });
});

// SHOW SEARCH ANIMATED LOADER

function showSearchLoader(tag = ''){
    if (tag) {
        tag = '-' + tag + '- ';
    }
    $('form').hide();
    $('#search-results').html(`<h2>LOADING ${tag}RESULTS</h2>`);
    $('#search-results').css('opacity', 1);
}

// DISPLAY RESULTS

function prepareResultsPage(data) {

    // $('#search-results').css('opacity', 0);
    console.log('prepareResultsPage');

    $('#search-results').empty();
    $('#search-results').html(data);

    // if result count is 0
    if ($('#search-results > div').data('results-number') == 0 ) {

        $('#text').val('');
        $('input[name=text]').focus();
        
        $('#search-results > h1').css('opacity', 0.3);
        $('#search-results > .default-grid').css('display', 'none');

        $('#search-results').css('opacity', 1);

    // if result count is > 0
    } else {

        // footer reset
        $('.page-search .container').css('min-height','unset');
        $('.page-search .footer-results').css('display','flex');
        setTimeout(function(){ 
            $('.page-search .footer-results').css('opacity', 1);
        }, 1000);

        $('#search-results > h1').css('opacity', 1);
        $('#search-results > .default-grid').css('display', 'grid');
        $('#search-tags').hide();
        $('form').hide();

        $('body').css('overflow', 'hidden');
        // $('main, header, footer').css('padding-right', 'calc(1.4rem + 10px)');

        $('html').css('scroll-behavior','smooth')

        // HABILITATE RESULTS INTERACTIONS 
        $('#search-results').find('a').on('click', resultClickHandler);

        // loadMore etc
        $( document ).ready(function() {

            let loadmore_container_class = '.delayed-loadmore-container';
            let loadmore_items_class = '.loadmore-item';
            $(loadmore_container_class).find(loadmore_items_class).css('display', 'none');
            loadMore($('.page-search').first(),loadmore_container_class);

            lazyLoadUpdate();
            mouseAnimate();
            setTimeout(function(){
                lazyLoadUpdate();
                $('#search-results .default-grid').css('opacity', 1);
                console.log('Results displayed');
                $('.footer-results').css('display', 'flex');

            }, 200);
        });
    }


}

// on clicking a result 
function resultClickHandler (e) {

    let link = $(this);

    console.log('Clicked on a result', link);

    link.css('filter','invert(1)');

    // special case : clicking on pub result
    if (link.hasClass('js-pub-result')) {

        if ($('.template-publications')[0]) {
            e.preventDefault();
            console.log('Scrolling to publication result', link.data('pub') );
            gotoElement($(link.data('pub')),'pub-newload');
        }
    }

    // hide menu
    closeMenu(false);

    // hide search (timeout in case a new page has to open)
    setTimeout( () => hideSearch('slow') , 300);

}


// ============================================================================
// publications image download 
// ============================================================================

function figuresLoader(){
    $('.figures-loader').on('click', function(event){
    
        let url = $(this).data('url');
        let id = $(this).data('id');
        let params = 'id=' + id;
    
        event.preventDefault();
    
        $.ajax({
            type: 'GET',
            url: url,
            data: {
                'id': id,
            },
            success: function() {
                window.location = url+"?"+params;
            }
        });
    
    });
}
if ($('.figures-loader').length) {
    onLoadFunctions.push(figuresLoader);
}


// ============================================================================
// LOAD MORE 
// ============================================================================

function loadMore(
    scrollable_element = $(window),
    container_class = '.loadmore-container', 
    items_class = '.loadmore-item'){

    $(container_class).each(function(){

        // initialization
        let container = $(this);
        let children_type, paginate_by;
        if (!(children_type = container.data('children-type'))) {
            children_type = 'block';
        }
        if (!(paginate_by = container.data('pag'))) {
            paginate_by = 10;
        }
        let total_items = container.find(items_class).length;

        // loading first items
        let counter = 1;
        loadItems(paginate_by);

        // debounced event listener
        let scheduled = null; 
        scrollable_element.on('scroll', myPageScroll);

        // auxiliary functions
        function loadItems(n) {
            for (let i = 0; i < n && counter <= total_items; i++) {

                let child = container.find(`${items_class}:nth-child(${counter})`).first();
                child.css('opacity', 0);
                child.css('transition', 'opacity 1s ease');
                child.css('display',children_type);

                console.log(`${items_class}:nth-child(${counter})`);

                counter++;
            }
            lazyLoadUpdate();
            setTimeout(()=>{loadMoreUpdate();}, 300);
            
        }

        function myPageScroll(event) {

            if (isVisible(container.next()) || isVisible(container.parent().next())) {
                if (!scheduled) {
                    setTimeout(() => {
                        loadItems(paginate_by);
                        scheduled = null;
                    }, 500);
                }
                scheduled = event;
            }
            // stop listening at full load
            // if (counter > total_items) {
            //     window.removeEventListener('scroll', myPageScroll);
            // }
        }

    });
}

function loadMoreUpdate(){
    $('.loadmore-item').each(function() {

        let item = $(this);

        console.log("LOADMORE", item[0], isVisible(item));

        if (isVisible(item)) { 
            item.css('opacity', 1); 
            item.removeClass('loadmore-item');
        }

    });
}

if ($('.loadmore-container').length) {

    let loadmore_container_class = '.loadmore-container';
    let loadmore_items_class = '.loadmore-item';

    if (window.location.hash.substr(1)) {

        // locate anchor pub and hide only further pubs
        let id_selector = '#' + window.location.hash.substr(1);
        pub_found = false;
        pub_count = 10;
        $(loadmore_container_class).find(loadmore_items_class).each(function(){
            pub_count++;
            if (pub_found) {
                $(this).css('display', 'none');
            } else {
                if ($(this).attr('id') == id_selector) {
                    pub_found = true;
                }
            }
        });

    } else {
        $(loadmore_container_class).find(loadmore_items_class).css('display', 'none'); // test other way speed
    }

    onLoadFunctions.push(loadMore);
    onLoadFunctions.push(loadMoreUpdate);
}

if ($('.delayed-loadmore-container').length) {

    let loadmore_container_class = '.delayed-loadmore-container';
    let loadmore_items_class = '.loadmore-item';
    $(loadmore_container_class).find(loadmore_items_class).css('display', 'none'); // test other way speed

    // onLoadFunctions.push(loadMore);
    // onLoadFunctions.push(loadMoreUpdate);
}

// ============================================================================
// VIDEOS
// ============================================================================
// set youtube preview if we dont have picture
// https://img.youtube.com/vi/7YFNf1ix_yY/0.jpg
// http://img.youtube.com/vi/<video-id>/<resolution><image>.jpg
// http://img.youtube.com/vi/<insert-youtube-video-id-here>/maxresdefault_live.jpg
//    onLoadFunctions.push(smoothScroll);

function loadYoutubePreviews(callback){
        
    $('.youtube-preview').each(function(){

        let item = $(this);

        if ( item.attr('src').length == 0 ){

            let yt_url = item.data('youtube');
            let yt_id_i = yt_url.indexOf('v=') + 2;
            let yt_id = yt_url.slice(yt_id_i, yt_id_i + 11);

            let url = `https://img.youtube.com/vi/${yt_id}/maxresdefault.jpg`;

            item.attr( 'src' , url );

        }

    });

}



// ============================================================================
// WINDOW SCROLL
// ============================================================================

var previousScroll = 0;

$(window).scroll(function(){

    updateHeader(update_header_settings);
    lazyLoadUpdate();
    loadMoreUpdate();
    adjustMainPadding();
    hideHeaderByFooter();

    var scroll = $(this).scrollTop();
    // console.log(scroll);

    if (scroll > previousScroll){

        // downscroll code

        // hide the about submenu
        if ( $(' .template-about-common ')[0] && mobile ) {
            $('nav.menu-about').hide();
        }

    } else {

        // upscroll code

        // display the about submenu
        if ( $(' .template-about-common ')[0] && mobile ) {
            $('nav.menu-about').show();
        }
    }
    previousScroll = scroll;

});


// ============================================================================
// WINDOW RESIZE
// ============================================================================

$(window).resize(function(){
        
    calcScreen();

    // safari vh bug
    // Update the element's size
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if ( $(' .template-homepage ').length ) {
        loadTextAdjustment();
        setHomepageInteraction();
    }

    // make the about submenu visible if we leave mobile
    if ( $(' .template-about-common ')[0] && !mobile ) {
        $('nav.menu-about .ul').removeAttr("style");
        $('nav.menu-about').show();
    }

    updateHeader(update_header_settings);
    adjustMainPadding();
    hideHeaderByFooter();
});


// ============================================================================
// mobile menu

let showMenu = 0;

function mobileMenu(){

    // mobile menu opening plus sign
    const opening_trigger = document.querySelector(".animated-cross-menu");
    opening_trigger.addEventListener("click", openMenu);

    // mobile menu close cross
    const closing_trigger = document.querySelector(".close-menu");
    closing_trigger.addEventListener("click", closeMenu);

    // results footer menu opener
    const opening_trigger_footer = document.querySelectorAll(".js-footer-burger");
    const opening_animation_element_footer =  document.querySelector(".burger-btn");
    opening_trigger_footer.forEach( (each) => each.addEventListener("click", openMenu) );

}

function openMenu() {

    console.log('openMenu');

    const menu = document.querySelector("header nav.menu");
    const header = document.querySelector("header");

    hideSearch();

    document.body.classList.add("overflow-hidden");

    if (header.classList.contains("hidden")) {
        showMenu = 2;
        header.classList.remove("hidden");
    } else {
        showMenu = 1;
    }

    menu.classList.add("show");

}

function closeMenu(hide_search = true) {

    console.log('closeMenu');

    const menu = document.querySelector("header nav.menu");
    const header = document.querySelector("header");

    if (hide_search) { hideSearch(); }
    
    document.body.classList.remove("overflow-hidden");

    if (showMenu === 2) {
        header.classList.add("hidden");
    }
    showMenu = 0;

    menu.classList.remove("show");

    // Reset the menu state
    showMenu = 0;

}

if ($('.animated-cross-menu').length) {
    onLoadFunctions.push(mobileMenu);
}
